<template>
  <div>
    <NavBarVue/>
    <template v-if="!cameraView && !isSubmitted">
      <div class="main-body-div">
        <div class="idnetity-main">
          <h3 class="pb-2">Provide a proof of address</h3>
          <p class="font-12">Take a picture of a valid proof of address document.</p>
          <div class="mt-3">
            <p class="font-8 text-uppercase mt-2 gray-color">Examples of acceptable documents</p>
          </div>
          <div class="d-flex pb-3">
            <div class="col-5 pt-2">
              <div class="border height d-flex justify-content-center align-items-center">
                <ul class="ins-list ps-2">
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/blue-tick.svg">
                    </span>
                    <p class="font-8">Utility bill</p>
                  </li>
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/blue-tick.svg">
                    </span>
                    <p class="font-8">Lease agreement</p>
                  </li>
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/blue-tick.svg">
                    </span>
                    <p class="font-8">Credit card bill or statement</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-1 pt-2"></div>
            <div class="col-5 pt-2">
              <div class="border height d-flex justify-content-center align-items-center">
                <ul class="ins-list ps-2">
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/blue-tick.svg">
                    </span>
                    <p class="font-8">Property tax receipt</p>
                  </li>
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/blue-tick.svg">
                    </span>
                    <p class="font-8">Insurancy policy</p>
                  </li>
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/blue-tick.svg">
                    </span>
                    <p class="font-8">Bank reference letter</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="py-3">
            <b class="font-14">Please make sure to:</b>
            <ul class="ins-list pt-3">
              <li class="pb-3">
                <span class="list-span">1</span>
                <p class="font-14">Your name and address is clearly visible</p>
              </li>
              <li class="pb-3">
                <span class="list-span">1</span>
                <p class="font-14">All corners of the document are in frame</p>
              </li>
            </ul>
          </div>
          
          <div class="btn-div">
            <button class="main-btn" role="button" @click="toggleCamera()">
                Open camera
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cameraView && !isSubmitted">
      <div class="main-body-div doc-verify" v-if="!documentUpload">
          <div class="front-doc-main text-center cus-margin">
          <p class="font-14 pb-4" v-show="!isPhotoTaken">Position your document within the frame.</p>
          <p class="font-14 pb-4" v-show="isPhotoTaken">Make sure your details are clear and unobstructed</p>
          <div id="app" :class="!isPhotoTaken ? 'web-camera-container' : 'picture-container'">
              
              <div v-show="isCameraOpen && isLoading" class="camera-loading">
              <ul class="loader-circle">
                  <li></li>
                  <li></li>
                  <li></li>
              </ul>
              </div>
              
              <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
              
              <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
              <div class="video-div">
                <video v-show="!isPhotoTaken" ref="camera" playsinline autoplay :class="{'flip-camera': currentFacingMode === 'user'}"></video>
                <img src="../assets/images/cam-switch.svg" alt=""  v-show="!isPhotoTaken && swapCamera" @click="switchCamera">
              </div> 
              <div class="canvas-div">
                <!-- <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas> -->
                <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="width" :height="height"></canvas>
              </div>
              </div>
  
          </div>
          <div class="btn-div pt-2" v-if="(isCameraOpen && !isLoading && !isPhotoTaken)" @click="takePhoto">
              <button class="main-btn">
              Capture
              </button>
          </div>
          <div class="btn-div pt-4" v-if="(isPhotoTaken)" @click="retake">
              <button class="light-btn">
              Retake photo
              </button>
          </div>
          <div class="btn-div pt-2" v-if="(isPhotoTaken)">
            <button class="main-btn" @click="submit()">
            Submit photo
            </button>
          </div>
          <div class="back-btn text-center pt-4" @click="backToDocument()">
            <button class="gray-btn ps-2">Upload your document instead</button>
          </div>
          <div class="back-btn text-center pt-4" @click="$router.go(-1)">
            <!-- <img src="../assets/images/arrow-left.svg" alt=""> -->
            <i data-feather="arrow-left" stroke="gray" stroke-width="3" height="18"></i>
            <button class="gray-btn ps-2">Back to previous</button>
          </div>
          </div>
      </div>
      <template v-if="documentUpload">
        <div class="main-body-div doc-verify cus-margin">
          <div class="idnetity-main">
            <h3 class="text-center">Upload Address Proof</h3>
            <div v-if="!uploadedFile">
              <input ref="fileInput" type="file" accept=".png, .jpg, .jpeg" class="d-none" @change="fileInputOnChangeHandler($event)" />
              <div role="button" class="pb-3 d-flex justify-content-center pt-2" @click="$refs.fileInput.click()">
                <div class="pt-2 ">
                  <div class="border height d-flex justify-content-center align-items-center">
                    <img src="../assets/images/Upload.svg">
                  </div>
                  <p class="text-center pt-1 font-8">Address Page</p>
                </div>
              </div>
              <div class="border">
                <ul class="ins-list ps-2">
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/tick.svg">
                    </span>
                    <p class="font-8">Files accepted: JPEG/JPG/PNG (Max size: 10 MB) </p>
                  </li>
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/tick.svg">
                    </span>
                    <p class="font-8">Make sure your details are clear and unobstructed </p>
                  </li>
                  <li class="pb-1">
                    <span>
                      <img src="../assets/images/tick.svg">
                    </span>
                    <p class="font-8">Document must be valid</p>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="uploadedFile" class="pb-3 d-flex justify-content-center pt-2">
              <img :src="uploadedFile" class="img-max" />
            </div>
            <div class="btn-div pt-2" v-if="uploadedFile">
              <button class="light-btn" @click="removeImg('front')">
                Remove Image
              </button>
            </div>
            <div class="btn-div pt-3"> 
              <button class="main-btn" role="button" @click="submit()" :disabled="uploadedFile == ''">
                Continue
              </button>
            </div>
            <div class="back-btn text-center pt-4" @click="$router.go(-1)">
              <!-- <img src="../assets/images/arrow-left.svg" alt=""> -->
              <i data-feather="arrow-left" stroke="gray" stroke-width="3" height="18"></i>
              <button class="gray-btn ps-2">Back to previous</button>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-if="isSubmitted">
      <div class="main-body-div">
        <div class="navbar-main">
          <b-container class="pt-4 centered">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            <div>
              <p class="font-12 text-center">Processing…</p>
            </div>
          </b-container>
        </div>
      </div>
    </template>
    <FooterCompVue/>
  </div>
</template>
  
  
<script>
  import NavBarVue from '../components/new-components/NavBar.vue';
  import FooterCompVue from '../components/new-components/FooterComp.vue';
  import { mapGetters } from "vuex";
  import feather from 'feather-icons';

  export default {
    computed: {
      ...mapGetters({
        kycData: "kycData",
      }),
    },
    name: 'ProofOfAddress',
    components: {
      NavBarVue,
      FooterCompVue
    },
    data() {
      return {
        width: 448,
        height: 336,
        cameraView: false,
        // web cam
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        documentUpload: false,
        uploadedFile: '',
        isSubmitted: false,
        errorMsg: '',
        currentFacingMode: "environment",
        swapCamera: false
      }
    },
    methods: {
      switchCamera() {
        this.currentFacingMode = (this.currentFacingMode == "environment") ? "user" : "environment";
        this.stopCameraStream();
        this.createCameraElement();
      },
       // Canvas
      resizeCanvas(){
        console.log(this.$refs.container.offsetWidth); // logs 0
        this.width = this.$refs.container.offsetWidth
        this.height = this.$refs.container.offsetHeight
      },
      // web cam
      toggleCamera() {
        this.cameraView = true
        if(this.isCameraOpen) {
          this.isCameraOpen = false;
          this.isPhotoTaken = false;
          this.isShotPhoto = false;
          this.stopCameraStream();
        } else {
          this.isCameraOpen = true;
          this.createCameraElement();
        }
      },
      createCameraElement() {
        this.isLoading = true;
        const constraints = (window.constraints = {
          audio: false,
          video: {facingMode: {exact: 'environment'}}
        });

        navigator.mediaDevices.enumerateDevices().then((devices) => {
          var cameras = devices.filter((device) => {
            return device.kind === 'videoinput';
          });
          if (cameras.length > 1) {
            this.swapCamera = true;
          } else {
            this.swapCamera = false;
          }
        });

        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          this.isLoading = false;
          console.log('errorerror',error)
          const constraints = (window.constraints = {
          audio: false,
            video: {facingMode: 'user'}
          });
          navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          this.isLoading = false;
          console.log('errorerror',error)
          alert("May the browser didn't support or there is some errors.");
        });
        });
      },
      stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach(track => {
          track.stop();
        });
      },
      retake() {
        this.isCameraOpen = true;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.createCameraElement();
      },
      takePhoto() {
        if(!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }
        
        this.isPhotoTaken = !this.isPhotoTaken;
        
        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
        this.uploadedFile = this.$refs.canvas.toDataURL();
        this.stopCameraStream();
      },
      // web cam ends
      submit() {
        this.isSubmitted = true;
        setTimeout(async() =>{ 
          this.kycData.addressProof = this.uploadedFile;
          await this.$store.commit("SET_KYC_DATA", this.kycData);
          this.$router.push({name: "thankyou"});
        }, 2000);
      },
      tryAgain() {
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.isLoading = false;
        this.documentUpload = false;
        this.uploadedFile = '';
        this.isSubmitted = false;
        this.errorMsg = '';
        this.isCameraOpen = true;
        this.createCameraElement();
      },
      fileInputOnChangeHandler(e) {
        const file = e.target?.files[0];
        if (file?.size > 10*(1024 * 1024)) {
          e.preventDefault();
          alert('Max allowed size is 10 MB');
          return;
        } else {
          this.previewImage(file);
        }
      },
      previewImage(file) {
        let reader = new FileReader();
        let vm = this;
        reader.onload = (e) => {
          vm.uploadedFile = e.target.result
        };
        reader.readAsDataURL(file);
      },
      removeImg(img) {
        if(img === 'front') {
          this.uploadedFile = '';
        } else {
          this.uploadedFileBack = '';
        }
      },
      backToDocument() {
        this.stopCameraStream();
        this.documentUpload = true;
        this.uploadedFile = '';
      }
    },
    mounted() {
      feather.replace();
      // canvas
      console.log(this.$refs.container.offsetWidth) // logs 0
      window.addEventListener("resize", this.resizeCanvas);
        this.resizeCanvas()  
    },
    unmounted() {
      window.removeEventListener("resize", this.resizeCanvas);
    }
  }
</script>
  
<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>