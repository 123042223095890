<template>
  <div>
    <NavBarVue/>
    <template v-if="!isSubmitted">
      <div class="main-body-div">
          <div class="idnetity-main">
            <h3 class="pb-2 text-center">Thank you</h3>
            <p class="font-12 text-center">That’s all we need to start verifying your identity.</p>
            <div class="btn-div pt-4">
              <button class="main-btn" role="button" @click="submit()">
                Submit for verification
              </button>
            </div>
          </div>
      </div>
    </template>
    <template v-if="isSubmitted">
      <div class="main-body-div">
        <div class="navbar-main">
          <b-container class="pt-4 centered">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            <div>
              <p class="font-12 text-center">Verifying your details. <br />
              This may take about a minute. Please do not close the page.</p>
            </div>
          </b-container>
        </div>
      </div>
    </template>
    <FooterCompVue/>
  </div>
</template>


<script>
import NavBarVue from '../components/new-components/NavBar.vue';
import FooterCompVue from '../components/new-components/FooterComp.vue';
import { mapGetters } from "vuex";
import axios from 'axios';

export default {
  computed: {
    ...mapGetters({
      kycData: "kycData",
    }),
  },
  name: 'ThankYou',
  components: {
    NavBarVue,
    FooterCompVue
  },
  data() {
    return {
      isSubmitted: false,
      errorMsg: '',
    }
  },
  methods: {
    async submit() {
      this.isSubmitted = true;
      let payload = {
        reference         : `SP_REQUEST_${Math.random()}`,
        // email             : this.kycData.email,
        country           : this.kycData.region,  
        verification_mode : "any",
      };
      payload['document'] = {
        proof : this.kycData.documentFront,
        additional_proof : this.kycData.documentBack,
        supported_types : ['passport', 'driving_license', 'id_card'],
        name : "",
        dob : "",
      };
      payload['face'] = {
        proof : this.kycData.faceProof
      };
      // payload['address'] = {
      //   proof : this.kycData.addressProof,
      //   supported_types  : ["utility_bill","lease_agreement", "credit_card_statement", "bank_statement", "tax_bill", "property_tax", "insurance_card", "bank_letter_receipt", "id_card"],
      //   name : [this.kycData.name]
      // };
      localStorage.setItem("reference", payload.reference)
      await axios.post('https://kycapi.aml-verify.com/suftiVerify', {
        payload
      }).then(async (res) => {
        console.log('suftiVerify res', res)
        if(res.status == 200) {
          payload = res.data;
          payload.firstname = res.data.documents?.verification_data?.document?.name.first_name;
          payload.lastname = res.data.documents?.verification_data?.document?.name.last_name;
          payload.phone = this.kycData.phone;
          payload.email = this.kycData.email;
          console.log('payload', payload)
          await axios.post('https://kycapi.aml-verify.com/submitVerification', {
            payload
          }).then( (res) => {
            if(res.status == 200) {
              localStorage.setItem("docReference", res.data.docID)
              if(res.data.event == 'verification.accepted') {
                this.isSubmitted = false;
                this.$router.push({name: "verified"});
              } else if (res.data.event == 'verification.declined') {
                this.isSubmitted = false;
                this.errorMsg = res.data.declined_reason
                this.$router.push({name: "failed"});
                localStorage.setItem("errorMsg", this.errorMsg);
                console.log('declined_reason', res.data.declined_reason)
              }
            }
          }).catch((err) => {
            this.$router.push({name: "failed"});
            localStorage.setItem("errorMsg", err);
          });
        }
      }).catch((err) => {
        this.$router.push({name: "failed"});
        localStorage.setItem("errorMsg", err);
      });
    }
  }
}
</script>

<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>