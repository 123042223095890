<template>
    <div>
      <NavBarVue/>
      <div class="main-body-div">
        <div class="idnetity-main">
          <div class="d-flex justify-content-center align-items-center">
            <img src="../assets/images/download.svg">
          </div>
          <h3 class="pt-4 pb-2 text-center">Download Started</h3>
          <p class="font-12 text-center">Your download should start automatically.
            <br>
            If it doesn't, you can download the BMC below.
          </p>
          
          <div class="btn-div pt-4">
              <button class="main-btn" role="button" @click="downloadBMC()">
                Download BMC
                <b-spinner small v-if="downloadStart"></b-spinner>
              </button>
          </div>
        </div>
      </div>
      <FooterCompVue/>
    </div>
</template>

<script>
    import NavBarVue from '../components/new-components/NavBar.vue';
    import FooterCompVue from '../components/new-components/FooterComp.vue';
    import axios from 'axios';

    export default {
        name: 'DocDownload',
        components: {
            NavBarVue,
            FooterCompVue
        },
        data() {
            return {
                dataPDF: '',
                date: '',
                downloadStart: false,
                reference: '',
            }
        },
        async mounted() {
            this.reference = localStorage.getItem("docReference");
            await this.shuftiStatus();
        },
        methods: {
            getFormattedDate(currDate) {
            const date = new Date(
                currDate._seconds * 1000 + currDate._nanoseconds / 1000000
            );
            let year = date.getFullYear()
            let month = ('0' + (date.getMonth() + 1)).slice(-2)
            let day = ('0' + date.getDate()).slice(-2)
            let hours = ('0' + date.getHours()).slice(-2)
            let minutes = ('0' + date.getMinutes()).slice(-2)
            return `${year}${month}${day}${hours}${minutes}`
            },
            shuftiStatus() {
            axios.post('https://kycapi.aml-verify.com/shuftiTransaction', {
                reference: this.reference
            }).then( (res) => {
                console.log('shuftiStatus res', res)
                if(res.status == 200) {
                    this.date = res.data.date;
                    this.dataPDF = res.data.documents;
                    this.qrImage = res.data.qrImage;
                    // localStorage.clear();
                }
            }).catch((err) => {
                console.log('shuftiStatus res', err)
            });
            },
            async downloadBMC() {
                this.downloadStart = true;
                const firstname = this.dataPDF.firstname ? this.dataPDF.firstname : this.dataPDF.verification_data.document.name.first_name;
                const lastname = this.dataPDF.lastname ? this.dataPDF.lastname : this.dataPDF.verification_data.document.name.last_name;
                const date = this.getFormattedDate(this.date);
                const filename = `BMC-QR-${date}-${firstname?.toUpperCase()}-${lastname?.toUpperCase()}.pdf`;
                axios.post('https://kycapi.aml-verify.com/generateBMCPDF', {
                    reference: this.reference,
                    filename: filename
                }, {
                    headers: { 'Content-Type': 'application/json' },
                    responseType: 'arraybuffer'
                }).then(async (resp) => {
                    const blob = new Blob([resp.data], {type: 'application/pdf'});
                    var downloadURL = window.URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = downloadURL;
                    link.download = filename;
                    link.click();
                    axios.post('https://kycapi.aml-verify.com/removepdf', {
                    file: filename
                    }).then(async (resp) => {
                    console.log('resp', resp)
                    this.downloadStart = false;
                    })
                });
            },
        }
    }
</script>