<template>
  <div>
    <div class="main-body-div" v-if="isSubmitted">
      <div class="navbar-main">
        <b-container class="pt-4 centered">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          <div>
            <p class="font-12 text-center">Preparing your document. <br />
            This may take a few moments.</p>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  
  name: 'QRscan',
  data() {
    return {
      isSubmitted: true,
      dataPDF: '',
      date: '',
      loading: false,
      reference: '',
      element: ''
    }
  },
  async mounted() {
    this.reference = this.$route.query.docID;
    await this.getData();
  },
  methods: {
    getFormattedDate(currDate) {
      const date = new Date(
        currDate._seconds * 1000 + currDate._nanoseconds / 1000000
      );
      let year = date.getFullYear()
      let month = ('0' + (date.getMonth() + 1)).slice(-2)
      let day = ('0' + date.getDate()).slice(-2)
      let hours = ('0' + date.getHours()).slice(-2)
      let minutes = ('0' + date.getMinutes()).slice(-2)
      return `${year}${month}${day}${hours}${minutes}`
    },
    getData() {
      axios.post('https://kycapi.aml-verify.com/shuftiTransaction', {
        reference: this.reference
      }).then(async (res) => {
          if(res.status == 200) {
            this.date = res.data.date;
            this.data = res.data.documents;
            const firstname = this.data.firstname ? this.data.firstname : this.data.verification_data.document.name.first_name;
            const lastname = this.data.lastname ? this.data.lastname : this.data.verification_data.document.name.last_name;
            const date = this.getFormattedDate(this.date);
            const filename = `BMC-QR-${date}-${firstname?.toUpperCase()}-${lastname?.toUpperCase()}.pdf`;
            axios.post('https://kycapi.aml-verify.com/generateBMCPDF', {
              reference: this.reference,
              filename: filename
            }, {
              headers: { 'Content-Type': 'application/json' },
              responseType: 'arraybuffer'
            }).then(async (resp) => {
              const blob = new Blob([resp.data], {type: 'application/pdf'});
              var downloadURL = window.URL.createObjectURL(blob);
              var link = document.createElement('a');
              link.href = downloadURL;
              link.download = filename;
              link.click();
              this.$router.push({name: "downloaded"});
              axios.post('https://kycapi.aml-verify.com/removepdf', {
                file: filename
              }).then(async (resp) => {
                console.log('resp', resp)
                this.isSubmitted = false;
              })
            });
          }
          console.log('shuftiStatus res', res)
      }).catch((err) => {
          this.isSubmitted = false;
          console.log('shuftiStatus err', err)
      });
    },
    shuftiStatus() {
      axios.post('https://kycapi.aml-verify.com/shuftiTransaction', {
        reference: this.reference
      }).then(async (res) => {
        console.log('shuftiStatus res', res)
        if(res.status == 200) {
          this.date = res.data.date;
          this.dataPDF = res.data.documents;
          this.dataReady = true;
          setTimeout(() => {
            const element = document.getElementById("element-to-convert");
            var clonedElement = element.cloneNode(true);
            clonedElement.style.display = 'block';
            console.log('element', element);
            const firstname = this.data.firstname ? this.data.firstname : this.data.verification_data.document.name.first_name;
            const lastname = this.data.lastname ? this.data.lastname : this.data.verification_data.document.name.last_name;
            const date = this.getFormattedDate(this.date);
            const filename = `BMC-QR-${date}-${firstname?.toUpperCase()}-${lastname?.toUpperCase()}.pdf`;
            html2pdf(clonedElement, {
              margin: 2,
              filename: filename,
              html2canvas: { scale: 2,useCORS: true },
            });
            this.isSubmitted = false;
            clonedElement.remove();
          }, 3000);
        }
      }).catch((err) => {
        console.log('shuftiStatus err', err)
      });
    },
  }
}
</script>

<style scoped>
.top-heading {
  width: 100vw;
  background-color: #0796D0;
  color: white;
  padding: 1rem;
}
.section-ref{
  margin: 1rem;
  border: 1px solid #ecf7ef;
  background: #ecf7ef;
}
.section-ref-red{
  margin: 1rem;
  border: 1px solid #fdf4f4;
  background: #fdf4f4;
}
.section-GeneralData-table, .section-BrowserInfo-table, .section-GeoLocation-table, .section-VerificationDetails-table {
  font-size: 11px;
}
.section-proofs-table img {
  width: 250px !important;
}
.table-bordered tr, .table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered td {
  height: 30px;
}
.section-GeneralData .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-BrowserInfo .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-GeoLocation .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-VerificationDetails .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 30%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-RequestTimeLog .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 30%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-Proofs .head {
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 20%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
</style>

<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>