<template>
  <div>
    <NavBarVue/>
    <div class="main-body-div">
      <div class="idnetity-main">
        <div class="d-flex justify-content-center align-items-center">
          <img src="../assets/images/radioTick.svg">
        </div>
        <h3 class="pt-4 pb-2 text-center">We’ve verified your identity</h3>
        <p class="font-12 text-center">We have generated a Blockchain Made 
          <br>
          Certificate (BMC) as proof of verification.
        </p>
        <div class="d-flex justify-content-center align-items-center">
          <img :src="qrImage">
        </div>
        
        <div class="btn-div pt-4">
            <button class="main-btn" role="button" @click="downloadBMC()">
              Download BMC
              <b-spinner small v-if="downloadStart"></b-spinner>
            </button>
        </div>
      </div>
    </div>
    <FooterCompVue/>
  </div>
</template>


<script>
import NavBarVue from '../components/new-components/NavBar.vue';
import FooterCompVue from '../components/new-components/FooterComp.vue';
import axios from 'axios';

export default {
  
  name: 'Verified',
  components: {
    NavBarVue,
    FooterCompVue
  },
  data() {
    return {
      dataPDF: '',
      date: '',
      downloadStart: false,
      reference: '',
      qrImage: ''
    }
  },
  async mounted() {
    this.reference = localStorage.getItem("docReference");
    await this.shuftiStatus();
  },
  methods: {
    getFormattedDate(currDate) {
      const date = new Date(
        currDate._seconds * 1000 + currDate._nanoseconds / 1000000
      );
      let year = date.getFullYear()
      let month = ('0' + (date.getMonth() + 1)).slice(-2)
      let day = ('0' + date.getDate()).slice(-2)
      let hours = ('0' + date.getHours()).slice(-2)
      let minutes = ('0' + date.getMinutes()).slice(-2)
      return `${year}${month}${day}${hours}${minutes}`
    },
    shuftiStatus() {
      axios.post('https://kycapi.aml-verify.com/shuftiTransaction', {
        reference: this.reference
      }).then( (res) => {
        console.log('shuftiStatus res', res)
        if(res.status == 200) {
          this.date = res.data.date;
          this.dataPDF = res.data.documents;
          this.qrImage = res.data.qrImage;
          // localStorage.clear();
        }
      }).catch((err) => {
        console.log('shuftiStatus res', err)
      });
    },
    async downloadBMC() {
      this.downloadStart = true;
       const firstname = this.dataPDF.firstname ? this.dataPDF.firstname : this.dataPDF.verification_data.document.name.first_name;
        const lastname = this.dataPDF.lastname ? this.dataPDF.lastname : this.dataPDF.verification_data.document.name.last_name;
        const date = this.getFormattedDate(this.date);
        const filename = `BMC-QR-${date}-${firstname?.toUpperCase()}-${lastname?.toUpperCase()}.pdf`;
      axios.post('https://kycapi.aml-verify.com/generateBMCPDF', {
        reference: this.reference,
        filename: filename
      }, {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'arraybuffer'
      }).then(async (resp) => {
        const blob = new Blob([resp.data], {type: 'application/pdf'});
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
        axios.post('https://kycapi.aml-verify.com/removepdf', {
          file: filename
        }).then(async (resp) => {
          console.log('resp', resp)
          this.downloadStart = false;
        })
      });
    },
  }
}
</script>

<style scoped>
.top-heading {
  width: 100vw;
  background-color: #0796D0;
  color: white;
  padding: 1rem;
}
.section-ref{
  margin: 1rem;
  border: 1px solid #ecf7ef;
  background: #ecf7ef;
}
.section-ref-red{
  margin: 1rem;
  border: 1px solid #fdf4f4;
  background: #fdf4f4;
}
.section-GeneralData-table, .section-BrowserInfo-table, .section-GeoLocation-table, .section-VerificationDetails-table {
  font-size: 11px;
}
.section-proofs-table img {
  width: 250px !important;
}
.table-bordered tr, .table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered td {
  height: 30px;
}
.section-GeneralData .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-BrowserInfo .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-GeoLocation .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-VerificationDetails .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 30%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-RequestTimeLog .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 30%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-Proofs .head {
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 20%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
</style>