<template>
    <div class="cam-main-dashboard">
        <div class="body-div">
            <!---------------- header Start----------------->
            <div class="dashboard-header">
                <div class="heading-area">
                    <HeadDropdown/>
                    <h2>Scan BMC QR Code</h2>
                    <p>
                        Use your camera to scan a BMC QR code to locate the original saved on blockchain
                    </p>
                </div>
            </div>
            <!---------------- header End----------------->

            <div class="body-inner"  v-if="!isCameraOpen">
                <div class="idnetity-main pt-5 pt-md-0">
                    <h3 class="text-center pt-2 pt-md-0">Allow camera access</h3>
                    <p class="font-12 pt-2 text-center">When prompted, you must enable camera access to continue</p>
                    <div class="text-center">
                    <lord-icon
                        src="https://cdn.lordicon.com/vixtkkbk.json"
                        trigger="hover"
                        colors="primary:#121331,secondary:#0796d0"
                        style="width:250px;height:250px">
                    </lord-icon>
                    <p class="font-12 text-center">We cannot verify you without using your camera</p>
                    </div>
                    
                    <div class="btn-div pt-5"> 
                        <button role="button" class="main-btn" @click="toggleCamera()">
                            Enable Camera
                        </button>
                    </div>
                    <div class="back-btn text-center pt-4" @click="$router.go(-1)">
                        <!-- <img src="../../assets/images/Left-arrow-gray.svg" alt=""> -->
                        <i data-feather="chevron-left" stroke="gray" stroke-width="1.5"></i>
                        <button class="gray-btn ps-2">Back to previous</button>
                    </div>
                </div>
            </div>

            <template v-if="isCameraOpen">
                <!-- <div class="border-styling mt-5">
                    <div class=" inner-border-styling">

                    </div>

                </div>
                <div>
                    <div class="btn-div pt-5"> 
                        <div class="captur-modal">
                            <b-button 
                                class="cap-btn" 
                                @click="modalShow = !modalShow"
                            >
                                Capture
                            </b-button>

                            <b-modal hide-footer hide-header class="cap-modal"  id="modal-center" centered v-model="modalShow">
                                <div>
                                    <div class="dmi-inner">
                                        <div class="text-center pb-2">
                                            <img src="../../assets/images/error.svg" alt="">
                                        </div>
                                        <h2 class="text-center pb-2">Error</h2>
                                        <p class="text-center font-changed pb-4">
                                            We were unable to recognise and locate the scanned BMC QR code. 
                                        </p>
                                    </div>
                                    
                                    <div>
                                        <router-link to="/view-qr">
                                            <b-button class="w-100" variant="danger">
                                                Try again
                                            </b-button>
                                        </router-link>   
                                    </div>
                                </div>
                            </b-modal>
                        </div>
                    </div>
                </div> -->
                <div id="app" class="mt-5" :class="!isPhotoTaken ? 'web-camera-container' : 'picture-container'">
                
                    <div v-show="isCameraOpen && isLoading" class="camera-loading">
                    <ul class="loader-circle">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    </div>
                    
                    <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
                    
                    <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
                        
                    <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay playsinline></video>
                    
                    <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
                    </div>

                </div>
                <div class="btn-div pt-4" v-if="(isCameraOpen && !isLoading && !isPhotoTaken)" @click="takePhoto('front')">
                    <button class="main-btn">
                    Capture
                    </button>
                </div>
                <div class="btn-div pt-4" v-if="(isPhotoTaken)" @click="retake">
                    <button class="main-btn">
                    Retake photo
                    </button>
                </div>
                <div class="btn-div pt-2" v-if="(isPhotoTaken)">
                    <button class="light-btn" @click="submit()">
                    Submit photo
                    </button>
                </div>
            </template>
            
        </div>
    </div>
    
</template>
  
  
<script>
  import HeadDropdown from '../new-components/headDropdown.vue';
  import feather from 'feather-icons';

  export default {
    
    name: 'DashboardCam',
    mounted() {
        feather.replace();
    },
    components: {
        HeadDropdown
    },
    data() {
        return {
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            uploadedFile: '',
        }
    },
    methods: {
        // web cam
        toggleCamera() {
            if(this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        },
        createCameraElement() {
            this.isLoading = true;
            const constraints = (window.constraints = {
                audio: false,
                video: true
            });
            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                this.isLoading = false;
                this.$refs.camera.srcObject = stream;
            })
            .catch(error => {
                this.isLoading = false;
                alert("May the browser didn't support or there is some errors.");
            });
        },
        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            tracks.forEach(track => {
                track.stop();
            });
        },
        retake() {
            this.isCameraOpen = true;
            this.isPhotoTaken = false;
            this.isShotPhoto = false;
            this.createCameraElement();
        },
        takePhoto() {
            if(!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }
        
            this.isPhotoTaken = !this.isPhotoTaken;
            const context = this.$refs.canvas.getContext('2d');
            context.drawImage(this.$refs.camera, 0, 0, 337.5, 450);
            this.uploadedFile = this.$refs.canvas.toDataURL();
            this.stopCameraStream();
        },
        // web cam ends
        submit() {
            this.$router.push({
                name: "view-qr"
            });
        }
    }
  }
</script>
  
<style scoped>
    .web-camera-container {
        width: unset !important;
        background: #ececec !important;
        border: 1px solid #ececec !important;
        border-radius: 4px !important;
    }
</style>