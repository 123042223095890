<template>
  <div>
    <NavBarVue />
    <template v-if="!documentUpload && !isSubmitted">
      <div class="main-body-div doc-verify" v-if="!isCameraOpen">
        <div class="idnetity-main cus-margin">
          <h3 class="text-center">Allow camera access</h3>
          <p class="font-12 pt-2 text-center">
            When prompted, you must enable camera access to continue
          </p>
          <div class="text-center">
            <lord-icon
              src="https://cdn.lordicon.com/vixtkkbk.json"
              trigger="hover"
              colors="primary:#121331,secondary:#0796d0"
              style="width: 250px; height: 250px"
            >
            </lord-icon>

            <p class="font-12 text-center">
              We cannot verify you without using your camera
            </p>
          </div>
          <div class="btn-div pt-5">
            <button class="main-btn" @click="toggleCamera()">
              Enable Camera
            </button>
          </div>
          <div class="btn-div pt-2">
            <button class="light-btn" @click="documentUpload = true">
              Upload Document
            </button>
          </div>
          <div
            v-if="!isCameraOpen"
            class="back-btn text-center pt-4"
            @click="$router.go(-1)"
          >
            <img src="../assets/images/arrow-left.svg" alt="" />
            <button class="gray-btn ps-2">Back to previous</button>
          </div>
        </div>
      </div>
      <div class="main-body-div doc-verify" v-if="isCameraOpen && !isLicense">
        <div class="front-doc-main text-center cus-margin">
          <p class="font-14 pb-4" v-show="!isPhotoTaken">
            Position the front of your document in the frame
          </p>
          <p class="font-14 pb-4" v-show="isPhotoTaken">
            Make sure your details are clear and unobstructed
          </p>

          <div
            id="app"
            :class="
              !isPhotoTaken ? 'web-camera-container' : 'picture-container'
            "
          >
            <div v-show="isCameraOpen && isLoading" class="camera-loading">
              <ul class="loader-circle">
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>

            <div
              v-if="isCameraOpen"
              v-show="!isLoading"
              class="camera-box"
              :class="{ flash: isShotPhoto }"
            >
              <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>
              <div class="video-div">
                <video
                  v-show="!isPhotoTaken"
                  @loadedmetadata="onVideoLoaded"
                  ref="camera"
                  playsinline
                  autoplay
                  :class="{ 'flip-camera': currentFacingMode === 'user' }"
                ></video>
                <img
                  src="../assets/images/cam-switch.svg"
                  alt=""
                  v-show="!isPhotoTaken && swapCamera"
                  @click="switchCamera"
                />
              </div>

              <div class="canvas-div">
                <!-- <canvas
                  v-show="isPhotoTaken"
                  ref="canvas"
                  :width="width"
                  :height="height"
                  name=""
                ></canvas> -->
                <img v-show="showImage" ref="image" :width="width" :height="height" />
              </div>
            </div>
          </div>
          <div
            class="btn-div pt-2"
            v-if="isCameraOpen && !isLoading && !isPhotoTaken"
            @click="takePhoto('front')"
          >
            <button class="main-btn">Capture</button>
          </div>
          <div class="btn-div pt-4" v-if="isPhotoTaken" @click="retake">
            <button class="light-btn">Retake photo</button>
          </div>
          <div class="btn-div pt-2" v-if="isPhotoTaken">
            <button class="main-btn" @click="submit()">Submit photo</button>
          </div>
          <div class="back-btn text-center pt-4" @click="backToDocument()">
            <button class="gray-btn ps-2">Upload your document instead</button>
          </div>
        </div>
      </div>
      <div class="main-body-div doc-verify" v-if="isCameraOpen && isLicense">
        <div class="front-doc-main text-center cus-margin">
          <p class="font-14 pb-4" v-show="!isPhotoTaken">
            Position the back of your document in the frame
          </p>
          <p class="font-14 pb-2" v-show="isPhotoTaken">
            Make sure your details are clear and unobstructed
          </p>

          <div
            id="app"
            :class="
              !isPhotoTaken ? 'web-camera-container' : 'picture-container'
            "
          >
            <div v-show="isCameraOpen && isLoading" class="camera-loading">
              <ul class="loader-circle">
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>

            <div
              v-if="isCameraOpen"
              v-show="!isLoading"
              class="camera-box"
              :class="{ flash: isShotPhoto }"
            >
              <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>
              <div class="video-div">
                <video
                  v-show="!isPhotoTaken"
                  ref="camera"
                  playsinline
                  :class="{ 'flip-camera': currentFacingMode === 'user' }"
                  autoplay
                ></video>
                <img
                  src="../assets/images/cam-switch.svg"
                  alt=""
                  v-show="!isPhotoTaken && swapCamera"
                  @click="switchCamera"
                />
              </div>

              <div class="canvas-div">
                <!-- <canvas
                  v-show="isPhotoTaken"
                  id="photoTaken"
                  ref="canvas"
                  :width="width"
                  :height="height"
                ></canvas> -->
                <img v-show="showImageBack" ref="bImage" :width="width" :height="height" />
              </div>
            </div>
          </div>
          <div
            class="btn-div pt-4"
            v-if="isCameraOpen && !isLoading && !isPhotoTaken"
            @click="takePhoto('back')"
          >
            <button class="main-btn">Capture</button>
          </div>
          <div class="btn-div pt-4" v-if="isPhotoTaken" @click="retake">
            <button class="light-btn">Retake photo</button>
          </div>
          <div class="btn-div pt-2" v-if="isPhotoTaken">
            <button class="main-btn" @click="submit()">Submit photo</button>
          </div>
          <div class="back-btn text-center pt-4" @click="backToDocument()">
            <button class="gray-btn ps-2">Upload your document instead</button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="documentUpload && !isSubmitted">
      <div
        class="main-body-div doc-verify cus-margin"
        v-if="checked === 'passport'"
      >
        <div class="idnetity-main">
          <h3 class="text-center">Upload Passport</h3>
          <div v-if="!uploadedFile">
            <input
              ref="fileInput"
              type="file"
              accept=".png, .jpg, .jpeg"
              class="d-none"
              @change="fileInputOnChangeHandler($event, 'front')"
            />
            <div
              role="button"
              class="pb-3 d-flex justify-content-center pt-2"
              @click="$refs.fileInput.click()"
            >
              <div class="pt-2">
                <div
                  class="border height d-flex justify-content-center align-items-center"
                >
                  <img src="../assets/images/Upload.svg" />
                </div>
                <p class="text-center pt-1 font-8">Passport Page</p>
              </div>
            </div>
            <div class="border">
              <ul class="ins-list ps-2">
                <li class="pb-1">
                  <span>
                    <img src="../assets/images/tick.svg" />
                  </span>
                  <p class="font-8">
                    Files accepted: JPEG/JPG/PNG (Max size: 10 MB)
                  </p>
                </li>
                <li class="pb-1">
                  <span>
                    <img src="../assets/images/tick.svg" />
                  </span>
                  <p class="font-8">
                    Make sure your details are clear and unobstructed
                  </p>
                </li>
                <li class="pb-1">
                  <span>
                    <img src="../assets/images/tick.svg" />
                  </span>
                  <p class="font-8">Document must be valid</p>
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="uploadedFile"
            class="pb-3 d-flex justify-content-center pt-2"
          >
            <img :src="uploadedFile" class="img-max" />
          </div>
          <div class="btn-div pt-3">
            <button
              class="main-btn"
              role="button"
              @click="submit()"
              :disabled="uploadedFile == ''"
            >
              Continue
            </button>
          </div>
          <div class="btn-div pt-2" v-if="uploadedFile">
            <button class="light-btn" @click="removeImg('front')">
              Remove Image
            </button>
          </div>
          <div class="back-btn text-center pt-4" @click="$router.go(-1)">
            <img src="../assets/images/arrow-left.svg" alt="" />
            <!-- <i data-feather="arrow-left" stroke="gray" stroke-width="4" height="13"></i> -->
            <button class="gray-btn ps-2">Back to previous</button>
          </div>
        </div>
      </div>
      <div
        class="main-body-div doc-verify cus-margin"
        v-if="checked === 'license'"
      >
        <div class="idnetity-main">
          <h3 class="">Upload Driver's License</h3>
          <div>
            <div class="upload-license-div pb-3">
              <template v-if="!uploadedFile">
                <input
                  ref="fileInput"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  class="d-none"
                  @change="fileInputOnChangeHandler($event, 'front')"
                />
                <div
                  role="button"
                  class="col-sm-12 col-md-5 pt-2"
                  @click="$refs.fileInput.click()"
                >
                  <div
                    class="border height d-flex justify-content-center align-items-center"
                  >
                    <img src="../assets/images/Upload.svg" />
                  </div>
                  <p class="text-center pt-1 font-8">Driver’s License Front</p>
                </div>
              </template>
              <template v-else>
                <div class="col-sm-12 col-md-5 pt-2">
                  <div class="pb-3 d-flex justify-content-center pt-2">
                    <img :src="uploadedFile" class="img-max" />
                  </div>
                  <div class="back-btn text-center pt-4">
                    <button class="gray-btn ps-2" @click="removeImg('front')">
                      Remove Image
                    </button>
                  </div>
                </div>
              </template>
              <div class="col-1 pt-2"></div>
              <template v-if="!uploadedFileBack">
                <input
                  ref="fileInputBack"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  class="d-none"
                  @change="fileInputOnChangeHandler($event, 'back')"
                />
                <div
                  role="button"
                  class="col-sm-12 col-md-5 pt-2"
                  @click="$refs.fileInputBack.click()"
                >
                  <div
                    class="border height d-flex justify-content-center align-items-center"
                  >
                    <img src="../assets/images/Upload.svg" />
                  </div>
                  <p class="text-center pt-1 font-8">Driver’s License Back</p>
                </div>
              </template>
              <template v-else>
                <div class="col-sm-12 col-md-5 pt-2">
                  <div class="pb-3 d-flex justify-content-center pt-2">
                    <img :src="uploadedFileBack" class="img-max" />
                  </div>
                  <div class="back-btn text-center pt-4">
                    <button class="gray-btn ps-2" @click="removeImg('back')">
                      Remove Image
                    </button>
                  </div>
                </div>
              </template>
            </div>
            <div class="border" v-if="!uploadedFile && !uploadedFileBack">
              <ul class="ins-list ps-2">
                <li class="pb-1">
                  <span>
                    <img src="../assets/images/tick.svg" />
                  </span>
                  <p class="font-8">
                    Files accepted: JPEG/JPG/PNG (Max size: 10 MB)
                  </p>
                </li>
                <li class="pb-1">
                  <span>
                    <img src="../assets/images/tick.svg" />
                  </span>
                  <p class="font-8">
                    Make sure your details are clear and unobstructed
                  </p>
                </li>
                <li class="pb-1">
                  <span>
                    <img src="../assets/images/tick.svg" />
                  </span>
                  <p class="font-8">Document must be valid</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="btn-div pt-3">
            <button
              class="main-btn"
              role="button"
              @click="submit()"
              :disabled="uploadedFile == '' || uploadedFileBack == ''"
            >
              Continue
            </button>
          </div>
          <div class="back-btn text-center pt-4" @click="$router.go(-1)">
            <img src="../assets/images/arrow-left.svg" alt="" />
            <!-- <i data-feather="arrow-left" stroke="gray" stroke-width="4" height="13"></i> -->
            <button class="gray-btn ps-2">Back to previous</button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="isSubmitted">
      <div class="main-body-div doc-verify">
        <div class="navbar-main">
          <b-container class="pt-4 centered">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <p class="font-12 text-center">Processing…</p>
            </div>
          </b-container>
        </div>
      </div>
    </template>
    <FooterCompVue />
  </div>
</template>

<script>
import NavBarVue from "../components/new-components/NavBar.vue";
import FooterCompVue from "../components/new-components/FooterComp.vue";
import LoaderComVue from "../components/new-components/LoaderCom.vue";
import { mapGetters } from "vuex";
import feather from "feather-icons";
import Camera from "simple-vue-camera";

export default {
  name: "DocumentVerification",
  computed: {
    ...mapGetters({
      kycData: "kycData",
      windowWidth: "windowWidth",
    }),
  },
  components: {
    NavBarVue,
    FooterCompVue,
    LoaderComVue,
    Camera,
  },
  data() {
    return {
      isMobile: false,
      width: 448,
      height: 336,
      checked: "",
      // web cam
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      documentUpload: false,
      uploadedFile: "",
      uploadedFileBack: "",
      isLicense: false,
      isSubmitted: false,
      isError: false,
      isLoading: true,
      currentFacingMode: "environment",
      swapCamera: false,

      showImage: false,
      showImageBack: false,
      videoWidth: 0,
      videoHeight: 0,
      mediaStream: null,
      captureWidth: 1920, // Set desired capture width
      captureHeight: 1080 // Set desired capture height
    };
  },
  methods: {
    checkScreenSize() {
      console.log(
        "width:" + window.innerWidth + "height:" + window.innerHeight
      );
      if (window.innerWidth < window.innerHeight) {
        this.height = 448;
        this.width = 336;
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    switchCamera() {
      this.currentFacingMode =
        this.currentFacingMode == "environment" ? "user" : "environment";
      this.stopCameraStream();
      this.createCameraElement();
    },
    // Canvas
    resizeCanvas() {
      this.width = this.$refs.container.offsetWidth;
      this.height = this.$refs.container.offsetHeight;
    },
    // web cam
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;
      const constraints = (window.constraints = {
        audio: false,
        // video: {facingMode: {exact: 'environment'}}
        video: { facingMode: this.currentFacingMode},
      });

      navigator.mediaDevices.enumerateDevices().then((devices) => {
        var cameras = devices.filter((device) => {
          return device.kind === "videoinput";
        });
        if (cameras.length > 1) {
          this.swapCamera = true;
        } else {
          this.swapCamera = false;
        }
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.mediaStream = stream;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("errorerror", error);
          const constraints = (window.constraints = {
            audio: false,
            // video: {facingMode: 'user'}
            video: { facingMode: this.currentFacingMode },
          });
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
              this.isLoading = false;
              this.mediaStream = stream;
              this.$refs.camera.srcObject = stream;
            })
            .catch((error) => {
              this.isLoading = false;
              console.log("errorerror", error);
              alert("May the browser didn't support or there is some errors.");
            });
        });
    },
    onVideoLoaded() {
      this.videoWidth = this.$refs.camera.videoWidth;
      this.videoHeight = this.$refs.camera.videoHeight;
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },
    retake() {
      this.isCameraOpen = true;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.showImage = false;
      this.showImageBack = false;
      this.createCameraElement();
    },
    takePhoto(type) {
      // const canvas = this.$refs.canvas;
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;
        this.isPhotoTaken = !this.isPhotoTaken;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      // Calculate the capture width and height based on the aspect ratio of the video element
      const aspectRatio = this.videoWidth / this.videoHeight;
      let captureWidth = this.captureWidth;
      let captureHeight = this.captureHeight;
      if (aspectRatio < 1) {
        captureHeight = captureWidth / aspectRatio;
      } else {
        captureWidth = captureHeight * aspectRatio;
      }
      // Create a temporary canvas with the same dimensions as the video element
      const canvas = document.createElement('canvas');
      const context = canvas.getContext("2d");
      canvas.width = captureWidth;
      canvas.height = captureHeight;
      context.drawImage(this.$refs.camera, 0, 0, captureWidth, captureHeight);
      // if (this.isMobile)  context.drawImage(this.$refs.camera, 0, 0, 337.5, 450);
      // else context.drawImage(this.$refs.camera, 0, 0, captureWidth, captureHeight);
      // Convert canvas to Blob
      canvas.toBlob((blob) => {
        const image = this.$refs.image;
        const bImage = this.$refs.bImage;
        // Create a temporary URL from the Blob
        const imageUrl = URL.createObjectURL(blob);
        if(type == "front") {
          // Set image source to captured image URL
          image.src = imageUrl;
          this.showImage = true;
  
          // Show image
          // image.style.display = 'block';
        } else {
          this.showImage = false;
          bImage.src = imageUrl;
          this.showImageBack = true;
          // image.style.display = 'none';
          // bImage.style.display = 'block';
        }
      }, 'image/jpeg', 1);
      const base64Image = canvas.toDataURL('image/jpeg', 1.0);
      type == "front" ? (this.uploadedFile = base64Image) : (this.uploadedFileBack = base64Image);
      this.stopCameraStream();
    },
    // web cam ends
    submit() {
      console.log("this.checked", this.$store.state.kycData);
      if (
        this.checked == "license" &&
        this.uploadedFileBack == "" &&
        !this.documentUpload
      ) {
        this.isLicense = true;
        this.isCameraOpen = true;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.createCameraElement();
      } else {
        this.isSubmitted = true;
        setTimeout(async () => {
          this.kycData.documentFront = this.uploadedFile;
          this.kycData.documentBack = this.uploadedFileBack;
          console.log("SET_KYC_DATA", this.kycData);
          await this.$store.commit("SET_KYC_DATA", this.kycData);
          this.$router.push({
            name: "face-verification",
          });
        }, 2000);
      }
    },
    fileInputOnChangeHandler(e, type) {
      const file = e.target?.files[0];
      if (file?.size > 10 * (1024 * 1024)) {
        e.preventDefault();
        alert("Max allowed size is 10 MB");
        return;
      } else {
        this.previewImage(file, type);
      }
    },
    previewImage(file, type) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        type === "front"
          ? (vm.uploadedFile = e.target.result)
          : (vm.uploadedFileBack = e.target.result);
      };
      reader.readAsDataURL(file);
    },
    removeImg(img) {
      if (img === "front") {
        this.uploadedFile = "";
      } else {
        this.uploadedFileBack = "";
      }
    },
    backToDocument() {
      this.stopCameraStream();
      this.documentUpload = true;
      this.uploadedFile = "";
      this.uploadedFileBack = "";
    },
    async snapshot() {
      const camera = this.$refs.camera;
      const blob = await camera?.snapshot();

      // To show the screenshot with an image tag, create a url
      const url = URL.createObjectURL(blob);
      console.log('url', url)
    }
  },
  mounted() {
    feather.replace();
    this.checked = localStorage.getItem("checked");
    console.log("this.windowWidth", this.windowWidth);
    navigator.permissions.query({ name: "camera" }).then((res) => {
      if (res.state == "granted") {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    });
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    // canvas
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  
};
</script>

<style lang="scss">
.btn-div {
  flex-direction: unset !important;
  justify-content: center;
}
body {
  display: flex;
  justify-content: center;
}
video {
  -webkit-playsinline: true;
}
.web-camera-container {
  // margin-top: 2rem;
  // margin-bottom: 2rem;
  // padding: 2rem;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // border: 1px solid #ccc;
  // border-radius: 4px;
  // width: 800px;
  // background: #484848ed;
  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      // width: 750px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
.height {
  height: 100px;
  width: 175px;
}
.img-max {
  max-width: 250px;
  max-height: 250px;
}
</style>
<style scoped>
.centered {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 5px solid #ccc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0796d0 #ccc #ccc #ccc;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
