<template class="">
  <div class="bmc-main-warp">
    <RouterView />
    <b-modal ref="myModal" hide-footer hide-header>
      <div class="d-flex flex-column align-items-center">
        <h3>Install BMC</h3>
        <p class="py-3 text-center">Install BMC app to your home screen for easy access and full screen mode.</p>
        <div>
          <b-button v-if="showInstallButton" @click="installApp" class="install-btn">Install</b-button>
        </div>
      </div>
    </b-modal>
    <b-modal ref="iModal" hide-footer hide-header>
      <div class="d-flex flex-column align-items-center">
        <img src="./assets/images/logo.svg" alt="logo">
        <h3 class="mt-4">Install BMC</h3>
        <p class="py-3 text-center">Install BMC app to your home screen for easy access and full screen mode.</p>
        <p class="py-3 text-center">Just tap <img src="./assets/upload.svg" alt="upload" width="25" class="mb-1"> then "Add to Home Screen"</p>
      </div>
    </b-modal>
  </div>
 
</template>

<script>
export default {
  data() {
    return {
      deferredPrompt: null,
      showInstallButton: false,
      // isAppInstalled: localStorage.getItem('appInstalled') === 'true'
    };
  },

  mounted() {
    window.addEventListener('beforeinstallprompt', (event) => {
      console.log('beforeinstallprompt event fired');
      // Prevent the default install prompt
      event.preventDefault();

      // Store the deferred prompt object
      this.deferredPrompt = event;

      // Show the modal if the app is not installed
      // if (!this.isAppInstalled) {
        this.showModal();
      // }
    });
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      if (window.navigator.standalone) {
        console.log('window.navigator.standalone', window.navigator.standalone)
      } else {
        console.log('window.navigator.standalone', window.navigator.standalone)
        this.showIModal();
      }
    }
    window.addEventListener('load', () => {
      console.log('addEventListener load')
      if (window.navigator.standalone) {
        console.log('App is installed');
      } else {
        console.log('App is not installed');
      }
    });
  },

  methods: {
    showModal() {
      // Set the showInstallButton flag to true so that the install button is shown
      this.showInstallButton = true;

      // Show the modal
      this.$refs.myModal.show();
    },

    showIModal(){
      this.$refs.iModal.show();
    },

    installApp() {
      // Hide the install button
      this.showInstallButton = false;

      // Hide the modal
      this.$refs.myModal.hide();

      // Show the install prompt
      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        // Check if the user accepted the installation
        if (choiceResult.outcome === 'accepted') {
          // Mark the app as installed
          // localStorage.setItem('appInstalled', 'true');

          // Update the isAppInstalled flag
          // this.isAppInstalled = true;
        }

        // Clear the deferredPrompt object
        this.deferredPrompt = null;
      });
    }
  }
};
</script>
<style scoped>
  body{
    font-family: 'Inter', sans-serif !important;
  }
  .bmc-main-warp{
    width: 100%;
  }
  .install-btn{
    background: #0796D0;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    width: 175px;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }
</style>